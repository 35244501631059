import { useEffect, useMemo, useRef, useState } from "react";
import { useRequest } from "ahooks";

import UKToast from "@/common-components-src/components/UKToast/index";
import cookieUtils from "@/utils/cookieUtils";
import { getHotWordsApi, getSuggestWordsApi } from "@/common-components-src/api/header/index.api";
// import style from './style.module.scss'
import cn from "clsx";
import { EXPOSURE_RECORD, IExpoTrackParamsRecord, sendClickTrack } from "@/tracks/25156";
import {
  sendClickTrack as sendClickTrack25149,
  EXPOSURE_RECORD as EXPOSURE_RECORD25149,
  IExpoTrackParamsRecord as IExpoTrackParamsRecord25149,
} from "@/tracks/25149";
import { createTrackParams, EXPCONFIG_FAST } from "@/app/s/util";
import SearchBtn from "@/common-components-src/assets/icons/search-btn.svg";
import { useTranslation } from "@/i18n/client";

interface props {
  defaultKeyword?: string;
  tracks?: {
    logid?: string;
    channelName?: string;
    abver?: string;
  };
}
const JDISearch = ({ defaultKeyword, tracks }: props) => {
  const { t } = useTranslation();
  const { logid = "", channelName = "", abver = "" } = tracks || {};
  const [isFocusedSearchInput, setIsFocusedSearchInput] = useState(false);
  const isHideSearchInput = useRef<any>(null);
  const [keyword, setKeyword] = useState("");
  const [hotWordsList, setHotWordsList] = useState([]); //热词列表
  const [recentlySearched, setRecentlySearched] = useState<string[]>([]); //最近搜索词
  const [suggestWords, setSuggestWords] = useState<any[]>([]); //联想词
  // const [searchDropdownVisible,setSearchDropdownVisible] = useState(false) //搜索下拉面板显示与隐藏

  const commonTracks = useMemo(
    () => ({
      pvid: logid,
      logid,
      channelName,
      abver,
    }),
    [tracks],
  );

  const handleInputChange = (e: any) => {
    const keyword = e.target.value;
    setKeyword(keyword || "");
    debouncedHandleSuggestWordsRequest(keyword);
  };

  const handleSearchClick = () => {
    sendClickTrack25149.Search_SearchBtn({ ...commonTracks, keyWord: keyword });
    const resultKeyword = keyword.trim();
    if (resultKeyword) {
      updateRecentlySearched(resultKeyword);
      window.location.href = `/s?k=${encodeURIComponent(resultKeyword)}`;
    } else {
      UKToast.show({
        content: "Enter keywords to begin your search",
        icon: "warning",
      });
    }
  };
  const handleSearchKeyDown = (event: any) => {
    if (event.key === "Enter") {
      handleSearchClick();
    }
  };
  const handleSearchInputClick = () => {
    sendClickTrack25149.Search_SearchBox({ channelName });
  };

  //获取热词
  const getHotWords = async () => {
    const res = await getHotWordsApi({ num: 15 });
    const { code, data } = res;
    if (code === "200" && data?.hotWordsItems && data?.hotWordsItems?.length > 0) {
      setHotWordsList(data.hotWordsItems);
    }
  };

  //获取最近搜索词
  const getRecentlySearched = () => {
    setRecentlySearched(JSON.parse(cookieUtils.getLocalStorage("SEARCH_KEYWORDS_KEY") || "[]"));
  };

  //更新最近搜索词
  const updateRecentlySearched = (keyword: string) => {
    if (keyword) {
      const keys: string[] = recentlySearched.filter((item) => item !== keyword);
      keys.unshift(keyword);
      setRecentlySearched(keys.slice(0, 10));
      cookieUtils.setLocalStorage("SEARCH_KEYWORDS_KEY", JSON.stringify(keys.slice(0, 10)));
    }
  };

  //清空最近搜索词
  const clearRecentKeywords = () => {
    console.log("clearRecentKeywords");
    setRecentlySearched([]);
    cookieUtils.setLocalStorage("SEARCH_KEYWORDS_KEY", JSON.stringify([]));
    sendClickTrack25149.Search_Delete({
      channelName,
    });
  };
  const onMouseEnter = () => {
    clearTimeout(isHideSearchInput.current);
  };

  const onMouseLeave = () => {
    console.log("onMouseLeave");
    isHideSearchInput.current = setTimeout(() => {
      setIsFocusedSearchInput(false);
    }, 1000);
  };

  //获取联想词
  const handleSuggestWordsRequest = async (keywords: string) => {
    const res = await getSuggestWordsApi({ keywords });
    console.log("sxt-handleSuggestWordsRequest", res);
    const { code, data } = res;
    if (code === "200" && data && data.suggestItems) {
      setSuggestWords(
        data.suggestItems.map((item: any) => {
          let word = item.keyword;
          let highlighted = "";
          for (let char of word) {
            if (keyword.includes(char)) {
              highlighted += `<mark>${char}</mark>`;
            } else {
              highlighted += char;
            }
          }
          return {
            keyword: item.keyword,
            highlightedKeyword: highlighted,
          };
        }),
      );
    } else {
      setSuggestWords([]);
    }
  };

  // input输入防抖
  const { run: debouncedHandleSuggestWordsRequest } = useRequest(handleSuggestWordsRequest, {
    debounceWait: 500,
    debounceLeading: true,
    manual: true,
  });

  const handleSelectKeywords = (keyword: string, idx: number, input_kw: string) => {
    if (!keyword) {
      return;
    }
    console.log("sxt-handleSelectKeywords");
    setKeyword(keyword);
    updateRecentlySearched(keyword);
    sendClickTrack.Search_Sug_AssociativeWord({
      pos: idx,
      keyWord: keyword,
      Input_Keyword: input_kw,
      ...commonTracks,
    });
    window.location.href = `/s?k=${encodeURIComponent(keyword)}`;
  };
  useEffect(() => {
    if (defaultKeyword) {
      setKeyword(defaultKeyword);
    }
  }, [defaultKeyword]);

  useEffect(() => {
    if (keyword) {
      handleSuggestWordsRequest(keyword);
    }
  }, [keyword]);

  useEffect(() => {
    getHotWords();
    getRecentlySearched();
  }, []);

  return (
    <div
      className={cn("search-bar", {
        active: isFocusedSearchInput && !(suggestWords.length === 0 && recentlySearched.length === 0 && hotWordsList.length !== 0),
      })}
      onClick={() => setIsFocusedSearchInput(true)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <input
        className="search-input"
        value={keyword}
        placeholder="Search on Joybuy"
        onChange={handleInputChange}
        onKeyDown={handleSearchKeyDown}
        type="text"
        onClick={handleSearchInputClick}
        exptag={"exp|" + EXPOSURE_RECORD25149.Search_SearchBox_Expo}
        data-exptag-config={EXPCONFIG_FAST}
        data-exptag-json-param={createTrackParams<IExpoTrackParamsRecord25149["Search_SearchBox_Expo"]>({
          channelName,
        })}
      />
      <div className="search-btn-wrap">
        <button
          className="search-btn"
          onClick={handleSearchClick}
          exptag={"exp|" + EXPOSURE_RECORD25149.Search_SearchBtn_Expo}
          data-exptag-config={EXPCONFIG_FAST}
          data-exptag-json-param={createTrackParams<IExpoTrackParamsRecord25149["Search_SearchBtn_Expo"]>({
            channelName,
          })}
        >
          <SearchBtn />
        </button>
      </div>
      <div className="search-recommended-dropdown-layer">
        {suggestWords.length > 0 ? (
          <div className="search-view">
            <ul>
              {suggestWords.map((item, sugWord_idx) => (
                <li
                  className="suggestWordItem"
                  key={item.keyword}
                  onClick={() => handleSelectKeywords(item.keyword, sugWord_idx, keyword)}
                  dangerouslySetInnerHTML={{ __html: item.highlightedKeyword }}
                  exptag={"exp|" + EXPOSURE_RECORD.Search_Sug_AssociativeWord_Expo}
                  data-exptag-config={EXPCONFIG_FAST}
                  data-exptag-json-param={createTrackParams<IExpoTrackParamsRecord["Search_Sug_AssociativeWord_Expo"]>({
                    pos: sugWord_idx,
                    keyWord: item.keyword,
                    Input_Keyword: keyword,
                    ...commonTracks,
                  })}
                ></li>
              ))}
            </ul>
          </div>
        ) : (
          <>
            {recentlySearched && recentlySearched.length > 0 ? (
              <div className="search-view">
                <div className="title">Recently Searched</div>
                <button
                  className="delete-history-btn"
                  onClick={clearRecentKeywords}
                  exptag={"exp|" + EXPOSURE_RECORD25149.Search_Delete_Expo}
                  data-exptag-config={EXPCONFIG_FAST}
                  data-exptag-json-param={createTrackParams<IExpoTrackParamsRecord25149["Search_Delete_Expo"]>({
                    channelName,
                  })}
                >
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fill="currentColor"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12 5H4V13H12V5ZM3 4V13C3 13.5523 3.44772 14 4 14H12C12.5523 14 13 13.5523 13 13V4H3Z"
                    />
                    <rect fill="currentColor" x="2" y="4" width="12" height="1" rx="0.5" />
                    <rect fill="currentColor" x="6" y="2" width="4" height="1" rx="0.5" />
                    <rect fill="currentColor" x="7" y="7" width="4" height="1" rx="0.5" transform="rotate(90 7 7)" />
                    <rect fill="currentColor" x="10" y="7" width="4" height="1" rx="0.5" transform="rotate(90 10 7)" />
                  </svg>
                </button>
                <ul className="tags">
                  {recentlySearched.map((item: any) => (
                    <li
                      className="tag"
                      key={item}
                      exptag={"exp|" + EXPOSURE_RECORD25149.Search_History_Expo}
                      data-exptag-config={EXPCONFIG_FAST}
                      data-exptag-json-param={createTrackParams<IExpoTrackParamsRecord25149["Search_History_Expo"]>({
                        keyWord: item,
                        ...commonTracks,
                      })}
                    >
                      <div
                        onClick={() => {
                          setKeyword(item);
                          sendClickTrack25149.Search_History({
                            keyWord: item,
                            ...commonTracks,
                          });
                        }}
                      >
                        {item}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
            {hotWordsList && hotWordsList.length > 0 ? (
              <div className="search-view">
                <div className="title">Hot Search</div>
                <ul className="tags">
                  {hotWordsList.map((item: any, hotword_idx: number) => (
                    <li
                      className="tag"
                      key={item.keyword}
                      exptag={"exp|" + EXPOSURE_RECORD25149.Search_Hotword_Expo}
                      data-exptag-config={EXPCONFIG_FAST}
                      data-exptag-json-param={createTrackParams<IExpoTrackParamsRecord25149["Search_Hotword_Expo"]>({
                        keyWord: item.keyword,
                        pos: hotword_idx,
                        ...commonTracks,
                      })}
                    >
                      <div
                        onClick={() => {
                          setKeyword(item.keyword);
                          sendClickTrack25149.Search_Hotword({
                            keyWord: item.keyword,
                            pos: hotword_idx,
                            ...commonTracks,
                          });
                        }}
                      >
                        {item.keyword}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};

export default JDISearch;
